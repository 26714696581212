<template>
  <div style="margin-top: 80px">
    <v-sheet
      rounded

      class="mx-auto mt-5"
      color="rgba(255,255,255,0.01)"
      width="95%"
      height="500"
      elevation="1"
    >
      <h1 class="ma-5">Packages</h1>

      <v-card class="ma-5" elevation="5" max-width="500" color="rgba(255,255,255,0.01)">
        <v-card-title primary-title> VIP </v-card-title>
        <v-card-text> 4.99 + VAT</v-card-text>

          <v-img
            max-width="250"
            src="@/assets/vip.png"
          ></v-img>

          <v-card-actions>
            <v-btn outlined color="success">Add to cart</v-btn>
          </v-card-actions>

      </v-card>
    </v-sheet>
    <v-sheet
      rounded
      class="mx-auto"
      color="rgba(255,255,255,0.01)"
      width="95%"
      height="300"
      elevation="1"
    >
      <v-btn class="ma-5" outlined color="blue">Checkout</v-btn>
    </v-sheet>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>