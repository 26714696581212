<template>
<v-container fill-height>
        <v-layout row wrap align-center>
          <v-flex class="text-center">
            <v-card max-width="400" class="mx-auto">
                <v-card-title primary-title>
                    Hii
                </v-card-title>
            </v-card>
            <v-card max-width="400" class="mx-auto">
                <v-card-title primary-title>
                    Hii
                </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>